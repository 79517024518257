<template>
  <v-card :elevation="0" class="d-flex">
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card
      class="text-left px-1 mx-1"
      :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'"
      elevation="0"
      min-width="350"
      style="border-radius: 17px !important; overflow: hidden"
    >
      <v-card-text class="text--primary">
        <div class="mb-n2">Ocho</div>
        <p class="title ma-0" style="color: #f06a25">Metadata:</p>
        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> Order ID en FireStore </span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item['.key'])"
            >
              {{ item[".key"] }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> User ID en FireStore </span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.userId)"
            >
              {{ item.userId }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> Sistema Operativo </span>
        <span style="d-block">
          {{ item.orderChannel | filterChannel }}
        </span>
      </v-card-text>
    </v-card>

    <v-card
      class="text-left px-1 mx-1 flex-grow-1"
      :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'"
      elevation="0"
      style="border-radius: 17px !important; overflow: hidden"
      max-width="700"
    >
      <v-card-text class="text--primary">
        <v-alert
          style="padding: 8px"
          color="#555555"
          v-if="item.rejectedReason"
          type="error"
        >
          <p class="pl-1 mx-0" style="color: white">
            <small>Cancelada por {{ item.rejectedBy.name }}</small>
          </p>
          {{ item.rejectedReason }}
        </v-alert>
        <div class="mb-n2">{{ item.userName }}</div>
        <p class="title ma-0" style="color: #f06a25">Detalles del Cliente:</p>
        <v-divider class="my-2"></v-divider>

        <v-row>
          <v-col cols="12" md="6">
            <span class="d-block font-weight-bold"> Método de pago </span>
            <v-chip
              :color="
                item.orderPaymentMethod == 'tengo'
                  ? 'indigo'
                  : item.orderPaymentMethod == 'card'
                  ? 'pink '
                  : 'light-green'
              "
              style="display: inline-block"
              dark
              small
              class="font-weight-bold"
              :text-color="'white'"
            >
              {{ item.orderPaymentMethod }}
            </v-chip>
          </v-col>
        </v-row>

        <v-divider
          v-if="fraudAnalysis && fraudAnalysis.length"
          class="my-2"
        ></v-divider>

        <span
          v-if="fraudAnalysis && fraudAnalysis.length"
          class="d-block font-weight-bold mb-0 pb-0"
        >
          Análisis de fraude
        </span>

        <v-alert
          v-if="fraudAnalysis && fraudAnalysis.length"
          border="left"
          width="70%"
          class="mt-3 fraud-alert"
          :color="fraudColor"
          dark
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p
                class="font-weight-bold"
                v-for="e of fraudAnalysis"
                :key="e.alert"
              >
                <i
                  style="font-size: 30px"
                  class="fas fa-exclamation-circle mr-5 warning--text font-weight-bold"
                  v-if="e.result"
                ></i>

                <i
                  style="font-size: 30px"
                  class="fas fa-check-double mr-5 green--text darken-4 font-weight-bold"
                  v-if="!e.result"
                ></i>

                {{ e.alert }}
              </p>
            </v-col>
            <!-- <v-col class="percent" cols="12" sm="4" md="4">
              <div>
                <p>Sospecha de fraude</p>
                <h1>{{ getFraudPercent }}</h1>
              </div>
            </v-col> -->
          </v-row>
        </v-alert>

        <v-divider
          v-if="item.orderPaymentMethod == 'card' && item.cardData"
          class="my-2"
        ></v-divider>
        <v-row v-if="item.orderPaymentMethod == 'card' && item.cardData">
          <v-col cols="12" sm="12" md="12">
            <span class="d-block font-weight-bold mb-0 pb-0">
              Detalles de la tarjeta
            </span>

            <v-alert
              dense
              v-if="item.fraudMessage && !item.fraudApproval"
              border="left"
              width="70%"
              type="error"
              color="error"
              class="mt-3"
            >
              {{ item.fraudMessage }}
            </v-alert>

            <div class="mt-1 ml-1">
              <p v-if="item.cardData.issuer" class="ma-0">
                Banco emisor : <b>{{ item.cardData.issuer }}</b>
              </p>
              <p class="ma-0 mt-2">
                Tipo de tarjeta :
                <b
                  >{{ item.cardData.brand }} {{ item.cardData.type }}
                  {{ item.cardData.product }}</b
                >
              </p>
              <p v-if="item.cardData.endsIn" class="ma-0 mt-2 mb-3">
                Terminación : <b>{{ item.cardData.endsIn }}</b>
              </p>
              <a @click="showUserCards" class="ml-1"
                ><i class="fas fa-credit-card mr-2"></i>Ver tarjetas del
                cliente</a
              >
            </div>
          </v-col>
        </v-row>

        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> Teléfono del Cliente </span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.userPhone)"
            >
              {{ item.userPhone }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
        <a @click="whatsappDialog" class="ml-3"
          ><i class="fab fa-whatsapp mr-1"></i>Iniciar conversación</a
        >
        <v-divider v-if="item.userEmail" class="my-2"></v-divider>
        <span v-if="item.userEmail" class="d-block font-weight-bold">
          Correo electrónico del Cliente
        </span>
        <v-tooltip v-if="item.userEmail" right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.userEmail)"
            >
              {{ item.userEmail }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>

        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold">Estado del Onboarding</span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="filterOnboardingColor(item.userOnboardingStatus)"
              style="display: inline-block"
              v-on="on"
              dark
              text-color="white"
              @click="copyToClipboard(item.userOnboardingStatus)"
            >
              {{ item.userOnboardingStatus | filterOnboarding }}
              <v-icon class="ml-3">
                {{ item.userOnboardingStatus | filterOnboardingIcon }}</v-icon
              >
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>

        <v-divider class="my-2"></v-divider>
        <v-row v-if="item.location && item.location.length">
          <v-col cols="12" md="6">
            <span class="d-block font-weight-bold"> Ubicación Actual: </span>
            <a
              style="display: block"
              :href="
                'https://www.google.com/maps/search/?api=1&query=' +
                item.location[0] +
                ',' +
                item.location[1]
              "
              target="_blank"
            >
              {{
                item.location[0]
                  ? parseFloat(item.location[0]).toFixed(6) + ","
                  : "no disponible"
              }}{{
                item.location[1] ? parseFloat(item.location[1]).toFixed(6) : ""
              }}
              <i class="ml-1 fas fa-external-link-alt"></i>
            </a>
          </v-col>
        </v-row>
        <span class="d-block">{{ item.userAddressName }}</span>
        <div class="d-block">
          {{ item.userAddressNeighborhood }}
        </div>

        <div v-if="item.deliveryInfo && item.deliveryInfo.driverName">
          <p class="title ma-0" style="color: #f06a25">
            Detalles del Motorista:
          </p>
          <v-divider class="my-2"></v-divider>

          <span class="d-block font-weight-bold"> Motorista Asignado </span>
          <span class="d-block" style="text-transform: capitalize">
            {{ item.deliveryInfo.driverName }}
          </span>
          <v-divider class="my-2"></v-divider>
          <span class="d-block font-weight-bold"> Teléfono del Motorista </span>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-chip
                :color="
                  $vuetify.theme.dark
                    ? 'rgba(0, 0, 0, 0.4)'
                    : 'rgba(0, 0, 0, 0.1)'
                "
                style="display: inline-block"
                v-on="on"
                dark
                :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                @click="copyToClipboard(item.deliveryInfo.phone)"
              >
                {{ item.deliveryInfo.phone }}
              </v-chip>
            </template>
            <i class="far fa-copy mr-2"></i>
            <span>Click para copiar</span>
          </v-tooltip>
          <div v-if="item.orderInstructions">
            <v-divider class="my-2"></v-divider>
            <span class="d-block font-weight-bold">
              Instrucciones para el Motorista
            </span>
            <span class="d-block ml-1" style="text-transform: capitalize">{{
              item.orderInstructions
            }}</span>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card
      class="text-left px-1 mx-1 flex-grow-1"
      :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'"
      elevation="0"
      width="500"
      style="border-radius: 17px !important; overflow: hidden; max-width: 600px"
    >
      <v-card-text class="text--primary">
        <div class="mb-n2 font-weight-bold">{{ item.businessName }}</div>
        <p class="title ma-0 mt-2" style="color: #f06a25">
          Detalles del mandadito
        </p>
        <v-divider class="my-2"></v-divider>
        <h3
          class="mt-5 pa-3 white--text"
          style="background-color: gray"
          v-if="item.roundTrip"
        >
          <i style="font-size: 20px" class="fas fa-exclamation-circle"></i>
          Mandado de Ida y Vuelta <br />
          <small class="pl-1"
            >El chavo debe regresar a la ubicación del cliente.</small
          >
        </h3>
        <v-container>
          <v-row v-for="(point, index) of item.points" :key="index">
            <v-col cols="12" sm="12" md="12">
              <p class="d-block mb-5 mt-2">
                <v-chip color="primary" class="mr-3"> {{ index + 1 }} </v-chip>
                <span class="font-weight-bold">{{ point.zone }}</span>
                <i class="fas fa-long-arrow-alt-right mr-3 ml-3"></i>
                {{ point.address }}
              </p>

              <p>
                <span class="font-weight-bold">Instrucciones: </span
                >{{ point.instructions }}
              </p>

              <p>
                <span class="font-weight-bold">Responsable: </span
                >{{ point.name }}
              </p>

              <p>
                <span class="font-weight-bold">Teléfono: </span>
                {{ point.phone }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card
      class="text-left px-1 mx-1 flex-grow-1"
      :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'"
      elevation="0"
      style="border-radius: 17px !important; overflow: hidden"
    >
      <v-card-text style="min-width: 100%" class="text--primary">
        <div class="mb-n2">{{ item.businessName }}</div>
        <p class="title ma-0" style="color: #f06a25">Mapa de la entrega</p>

        <template>
          <v-divider class="my-2"></v-divider>
          <v-stepper v-model="selectedPoint">
            <v-stepper-header style="height: 100%">
              <v-stepper-step
                v-for="(point, index) of item.points"
                :key="index"
                editable
                @click="zoom = 1"
                :step="index + 1"
              >
                Punto {{ index + 1 }}
                <a
                  style="display: block"
                  class="mt-2"
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    point.geo.geopoint._lat +
                    ',' +
                    point.geo.geopoint._long
                  "
                  target="_blank"
                >
                  {{
                    point.geo.geopoint._lat
                      ? parseFloat(point.geo.geopoint._lat).toFixed(6) + ","
                      : "no disponible"
                  }}{{
                    point.geo.geopoint._long
                      ? parseFloat(point.geo.geopoint._long).toFixed(6)
                      : ""
                  }}
                  <i class="ml-1 fas fa-external-link-alt"></i>
                </a>
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                :key="index"
                v-for="(point, index) of item.points"
                :step="index + 1"
              >
                <div class="ml-3">
                  <p class="ma-0 pa-0" style="max-width: 900px">
                    {{ point.address }}
                  </p>
                </div>
                <v-col
                  style="
                    display: flex;
                    justify-content: center;
                    align-item: center;
                  "
                  md="12"
                  cols="12"
                  sm="12"
                >
                  <l-map
                    class="fill-height"
                    v-if="!loading && selectedPoint == index + 1"
                    style="height: 50vh"
                    :zoom="zoom"
                    :center="[
                      point.geo.geopoint._lat,
                      point.geo.geopoint._long,
                    ]"
                  >
                    <l-tile-layer :url="url"></l-tile-layer>
                    <l-marker
                      :lat-lng="[
                        point.geo.geopoint._lat,
                        point.geo.geopoint._long,
                      ]"
                      :draggable="false"
                      :icon="getImage"
                    >
                      <l-tooltip>{{ point.address }}</l-tooltip>
                    </l-marker>
                  </l-map>
                </v-col>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="whDialog" v-if="whDialog" max-width="600px">
      <add-message
        :sourceOrderId="item['.key']"
        :sourceUserId="item.userId"
        @cancel="whDialog = false"
        @success="handleSuccess"
        :sourceOrder="item"
      ></add-message>
    </v-dialog>

    <v-dialog
      persistent
      v-model="cardsDialog"
      v-if="cardsDialog"
      max-width="90%"
    >
      <user-cards :user="user" @cancel="cardsDialog = false"></user-cards>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import mapSkeleton from "@/components/skeleton/map-skeleton";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
import AddMessage from "../sudo/messages/AddMessage";
import userCards from "../sudo/users/edit-cards.vue";
import { db } from "@/firebase";

export default {
  name: "order-details",
  props: ["item"],
  components: {
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LPopup,
    mapSkeleton,
    LTooltip,
    VGeosearch,
    AddMessage,
    userCards,
  },
  data() {
    return {
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar comercio",
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 0,
      iconSize: 32,
      icon: L.icon(this.getImage),
      destinationPoint: [],
      startingPoint: [],
      loading: true,
      selectedPoint: 1,
      snackbarText: "",
      snackbar: false,
      whDialog: false,
      cardsDialog: false,
      user: null,
    };
  },
  watch: {
    zoom() {
      if (this.zoom < 28) {
        setTimeout(() => {
          this.zoom += 5;
        }, 100);
      }
    },
  },
  filters: {
    filterChannel(type) {
      let channel = {
        App_android: "Android",
        App_iOS: "iOS",
        ocho_cargo: "Ocho Cargo",
        ocho_menu: "Ocho Menu",
      };

      return type && channel[type] ? channel[type] : "";
    },

    filterOnboarding(value) {
      let l = {
        placed: "Colocada",
        reviewed: "Corecciones pendientes",
        rejected: "Rechazado",
        completed: "Completado",
      };

      return value && l[value] ? l[value] : "No realizado";
    },

    filterOnboardingIcon(value) {
      let l = {
        placed: "fas fa-location-pin",
        reviewed: "fas fa-clock",
        rejected: "fas fa-times",
        completed: "fas fa-check-double",
      };

      return value && l[value] ? l[value] : "fas fa-times";
    },
  },
  computed: {
    fraudAnalysis() {
      return this.item.fraudAnalysis
        ? this.item.fraudAnalysis.filter((e) => e.result)
        : null;
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1];
    },

    getImage() {
      return L.icon({
        iconUrl: require("@/assets/user.svg"),
        shadowUrl: require("@/assets/map-marker.png"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },

    getFraudPercent() {
      let fraudPercent = 0;

      this.item.fraudAnalysis.forEach((e) => {
        if (e.result) {
          fraudPercent = fraudPercent + 100 / this.item.fraudAnalysis.length;
        }
      });

      return `${fraudPercent.toFixed(2)} %`;
    },
    fraudColor() {
      let fraudPercent = 0;

      this.item.fraudAnalysis.forEach((e) => {
        if (e.result) {
          fraudPercent = fraudPercent + 100 / this.item.fraudAnalysis.length;
        }
      });

      if (fraudPercent > 0 && fraudPercent < 50) {
        return "#ffdd37b0";
      }

      if (fraudPercent > 0) {
        return "#df323b73";
      }

      return "#4caf50ab";
    },
  },
  methods: {
    whatsappDialog() {
      this.whDialog = true;
    },
    filterOnboardingColor(value) {
      let l = {
        placed: "blue",
        reviewed: "warning",
        rejected: "red",
        completed: "green",
      };

      return value && l[value] ? l[value] : "red";
    },
    async showUserCards() {
      await this.$binding("user", db.collection("users").doc(this.item.userId));
      this.cardsDialog = true;
    },
    groupAddons(addons) {
      let result = [];
      if (addons) {
        addons.forEach((addon) => {
          let search = result.find((item) => item.groupName == addon.groupName);
          if (search) {
            search.options.push({ name: addon.name, quantity: addon.quantity });
          } else {
            let newAddon = addon;
            newAddon.options = [{ name: addon.name, quantity: addon.quantity }];
            result.push(newAddon);
          }
        });
      }

      return result;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    handleSuccess(data) {
      this.snackbarText = data;
      this.snackbar = true;
      this.whDialog = false;
    },
  },
  mounted() {
    this.loading = true;

    // this.startingPoint = [
    //   this.item.points[0].geo.geopoint._lat,
    //   this.item.points[0].geo.geopoint._long,
    // ];
    // this.destinationPoint = [
    //   this.item.points[1].geo.geopoint._lat,
    //   this.item.points[1].geo.geopoint._long,
    // ];

    this.zoom = 1;

    this.loading = false;
  },
};
</script>


<style scoped lang="scss">
#mapid {
  height: 40vh;
}

.leaflet-marker-icon {
  border-radius: 100%;
  background-color: #f96921;
  background-size: cover;
  animation-name: in;
  animation-duration: 3s;
}

.leaflet-shadow-pane {
  animation-name: in;
  animation-duration: 3s;
}

.fraud-alert {
  width: 70%;
}

@keyframes in {
  0% {
    left: -200px;
    top: -100px;
  }
  25% {
    left: 0;
    top: 0;
  }
}

@media only screen and (max-width: 900px) {
  .fraud-alert {
    width: 100%;
  }
}

.save-btn {
  margin-top: 20px;
}

.vue2leaflet-map {
  z-index: 1;
}

.percent {
  display: grid;
  justify-content: center;
  align-items: center;

  div {
    p {
      color: #FF1744;
      font-weight: bold;
    }
    h1 {
      color: rgb(83, 82, 82);
      font-size: 50px;
      font-weight: bold;
    }
  }
}
</style>
