<template>
    <div class="ma-5" style="position:relative;">
        <div style="position:absolute; z-index:999; width:100%; height:100%; display:flex; flex-direction:column;justify-content:center; align-items:center;">
        <lottie
            :options="defaultOptions"
            :height="300"
            :width="400"
            v-on:animCreated="handleAnimation"
        />
        <p
            :class="$vuetify.theme.dark?'subtitle-1 primary--text':'subtitle-1 black--text'"
        >Cargando Data</p>
        </div>
             <v-skeleton-loader
                height="94"
                type="card-heading"
                >
            </v-skeleton-loader>

            <v-skeleton-loader
                ref="skeleton"
                type="image"
                class="mx-auto d-none d-md-block"
            ></v-skeleton-loader>
    </div>
</template>

<script>
import Lottie from "../Lottie"
import * as animationData from "../../assets/ochoColor.json";
import { Parser } from "json2csv";

export default {
    name: "map-skeleton",
    components: {
        lottie: Lottie,
    },
     data() {
        return {
             animationSpeed: 1.1,
            defaultOptions: {
                animationData: animationData.default,
                loop: true,
                autoplay: true
            }
        }
     },
    methods : {
         handleAnimation: function(anim) {
            this.anim = anim;
            anim.setSpeed(this.animationSpeed);
        },
    }
}
</script>