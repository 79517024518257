<template>
  <div style="height: 75vh; position: relative">
    <v-btn
      style="right: 10px; top: 10px; z-index: 9999"
      icon
      color="primary"
      class="close"
      fab
      absolute
      dark
      @click="$emit('cancel')"
    >
      <v-icon size="50" style="color: black" dark>fa-times</v-icon>
    </v-btn>
    <l-map v-if="!loading" ref="map" :zoom="zoom" :center="center" class="map">
      <l-tile-layer :url="url" :attribution="attribution" />

      <l-marker :lat-lng="center" :icon="getImage"> </l-marker>
      <l-geo-json
        :options-style="styleFunction"
        :options="options"
        :geojson="geojson"
      />
    </l-map>
  </div>
</template>

<script>
import { db } from "@/firebase";
import L from "leaflet";
import { latLng } from "leaflet";
import "leaflet/dist/leaflet.css";

import {
  LMap,
  LTileLayer,
  LControlZoom,
  LMarker,
  LPopup,
  LTooltip,
  LGeoJson,
} from "vue2-leaflet";
require("leaflet-routing-machine");

require("leaflet-control-geocoder");

export default {
  name: "zone-validation",
  props: ["latitude", "longitude"],
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker,
    LPopup,
    LTooltip,
    LGeoJson,
  },
  data() {
    return {
      loading: true,
      polygons: [],
      zoom: 18,
      center: latLng(14.075104529559956, -87.19571461307869),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      map: null,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      geojson: null,
      fillColor: "#ff0000",
    };
  },

  computed: {
    getImage() {
      return L.icon({
        iconUrl: require("@/assets/user.svg"),
        shadowUrl: require("@/assets/map-marker.png"),
        iconSize: [32, 32],
        iconAnchor: [10, 39],
        shadowAnchor: [26, 45],
        popupAnchor: [-3, -76],
      });
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      const fillColor = this.fillColor;
      return () => {
        return {
          weight: 2,
          color: "#ff0000",
          opacity: 0.1,
          fillColor: fillColor,
          fillOpacity: 0.1,
        };
      };
    },
  },

  methods: {
    async init() {
      await this.$binding(
        "globals",
        db.collection(`settings`).doc("global")
      ).then(async (globals) => {
        this.geojson = JSON.parse(globals.coverageZone);

        this.geojson.features = this.geojson.features.filter(
          (e) => e.properties.color != "transparent"
        );

        this.loading = false;
      });
    },
  },

  async mounted() {
    this.center = latLng(this.latitude, this.longitude);
    this.init();
  },
};
</script>

<style>
.map {
  height: 70vh;
}
</style>
