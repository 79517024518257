<template>
  <div>
    <v-card class="px-2">
      <v-card-title>
        <span class="headline"> Nuevo Mensaje </span>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          :loading="saving"
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-3">
          <v-snackbar top :timeout="3000" v-model="snackbar">
            {{ snackbarText }}
          </v-snackbar>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectedTemplate"
                :items="sortedTemplates"
                placeholder="Escoge mensaje"
                outlined
                :loading="saving"
                hide-details
                item-text="name"
                item-value="value"
                rounded
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedTemplate && !sourceUserId">
            <v-col cols="12" md="12">
              <p>Id del usuario</p>
              <v-text-field
                v-model.trim="userId"
                :error-messages="userIdError"
                placeholder="Id de firebase del usuario"
                rounded
                outlined
                hide-details
                @input="$v.userId.$touch()"
                @blur="$v.userId.$touch()"
              ></v-text-field>
            </v-col>

            <v-col
              v-if="orderFieldRequired == true && !sourceOrderId"
              cols="12"
              md="12"
            >
              <p>Order Id</p>

              <v-text-field
                v-model.trim="orderId"
                :error-messages="orderIdError"
                placeholder="Id de firebase la orden"
                rounded
                outlined
                hide-details
                @input="$v.orderId.$touch()"
                @blur="$v.orderId.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-0 pt-0" cols="12" sm="12">
              <v-btn
                height="50px"
                block
                @click="submit()"
                :loading="saving"
                :disabled="!selectedTemplate"
                class="mt-4"
                color="primary"
              >
                Generar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { db, fb } from "@/firebase";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "add-message",
  components: {
    lottie: Lottie,
  },
  props: ["sourceOrderId", "sourceUserId", "sourceOrder"],
  mixins: [lottieSettings, validationMixin],
  data() {
    return {
      saving: false,
      snackbarText: "",
      snackbar: false,
      selectedTemplate: "",
      userId: "",
      orderId: "",
      orderFieldRequired: false,
      templates: [
        {
          name: "Atraso por parte del comercio",
          value: "mkt_order_late_by_business",
        },
        {
          name: "Atraso por parte de Ocho",
          value: "mkt_order_late_by_ocho",
        },
        {
          name: "Comercio cerrado, reversión de cobro",
          value: "business_closed_credit_card_reversion_es",
        },
        {
          name: "Chavo afuera con pedido",
          value: "product_delivery_es",
        },
        {
          name: "Cancelación por fuera de cobertura",
          value: "mkt_order_out_of_the_service_area",
        },
        {
          name: "Cancelación, pagó en efectivo.",
          value: "mkt_order_cash_cancelled_1",
        },
        {
          name: "Cancelación, pagó con billetera.",
          value: "mkt_order_wallet_cancelled",
        },
        {
          name: "Producto agotado",
          value: "product_unavailable_es",
        },
        {
          name: "General",
          value: "general_inquiry_es",
        },
        {
          name: "Encuesta de servicio bienvenida",
          value: "fixed_service_survey_es",
        },

        {
          name: "GPS mal colocados por parte del cliente (mandado)",
          value: "mkt_errand_wrong_location",
        },

        {
          name: "GPS mal colocados por parte del cliente (Comida)",
          value: "mkt_order_wrong_location",
        },

        {
          name: "Producto incompleto o erróneo",
          value: "mkt_incomplete_product",
        },
        {
          name: "Comercio sin productos",
          value: "mkt_product_out_of_stock",
        },
        {
          name: "Validación de tarjetas",
          value: "mkt_card_validation",
        },
        {
          name: "Cobro extra por Time Out 1",
          value: "mkt_timeout_1",
        },
        {
          name: "Cobro extra por Time Out 2",
          value: "mkt_timeout_2",
        },
        {
          name: "Explicación de reversión",
          value: "mkt_reversion_2",
        },
      ],
    };
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.userId.$invalid) return;
      if (this.orderFieldRequired && this.$v.orderId.$invalid) return;
      this.sendMessage();
    },
    async sendMessage() {
      let order;
      let user = await db.collection("users").doc(this.userId).get();

      if (this.orderFieldRequired) {
        order = await db.collection("orders").doc(this.orderId).get();
      }

      if (!user.exists) {
        this.snackbarText = "Usario no encontrado, por favor tratar de nuevo";
        this.snackbar = true;
        return;
      }

      if (this.orderFieldRequired && order && !order.exists) {
        this.snackbarText = "Orden no encontrada, por favor tratar de nuevo";
        this.snackbar = true;
        return;
      }

      let userName = user.data().name + " " + user.data().surname;
      this.saving = true;
      if (this.orderFieldRequired) {
        switch (this.selectedTemplate) {
          case "product_delivery_es":
            this.startConversation(
              userName,
              user.data().phone,
              order.data().shortId
            );
            break;
          case "product_unavailable_es":
            this.startConversation(
              userName,
              user.data().phone,
              order.data().businessName
            );
            break;
        }
      } else {
        this.startConversation(userName, user.data().phone);
      }
    },
    async startConversation(userName, userPhoneNumber, orderInformation) {
      let _params;
      if (this.orderFieldRequired) {
        _params = [userName, orderInformation];
      } else {
        _params = [userName];
      }

      const startConversation = fb
        .functions()
        .httpsCallable("httpMessabirdStartConversation");

      startConversation({
        userPhoneNumber: userPhoneNumber,
        templateName: this.selectedTemplate,
        params: _params,
      })
        .then(async () => {
          if (this.sourceOrder) {
            await this.loggedSMS();
          }
          this.saving = false;
          this.$emit("success", "Mensaje enviado");
        })
        .catch((err) => {
          console.log("err", err);
          this.saving = false;
          this.snackbarText = "Ocurrio un error, intentar de nuevo";
          this.snackbar = true;
        });
    },

    loggedSMS() {
      return new Promise((resolve) => {
        db.collection("messaging")
          .doc(this.sourceOrder.shortId)
          .set(
            {
              userName: this.sourceOrder.userName,
              userId: this.userId,
              businessName:
                this.sourceOrder && this.sourceOrder.businessName
                  ? this.sourceOrder.businessName
                  : "",
              businessId:
                this.sourceOrder && this.sourceOrder.businessId
                  ? this.sourceOrder.businessId
                  : "",
              orderId: this.orderId ? this.orderId : "",
              shortId: this.sourceOrder ? this.sourceOrder.shortId : "",
              progressCreatedAt: this.sourceOrder
                ? this.sourceOrder.progressCreatedAt
                : "",
              sentMessages: fb.firestore.FieldValue.arrayUnion({
                date: new Date(),
                template: this.selectedTemplate,
                sentBy: {
                  id: this.$store.state.user[".key"],
                  name: this.$store.state.user.name,
                },
              }),
            },
            { merge: true }
          )
          .then((res) => {
            resolve("success");
          })
          .catch((err) => {
            console.log(err);
            resolve("success");
          });
      });
    },
  },
  validations: {
    userId: {
      required,
    },
    orderId: {
      required: requiredIf(function () {
        return this.orderFieldRequired;
      }),
    },
  },
  computed: {
    sortedTemplates: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.templates.sort(compare);
    },
    userIdError() {
      const errors = [];
      if (!this.$v.userId.$dirty) return errors;
      !this.$v.userId.required && errors.push("User id es requerido");
      return errors;
    },
    orderIdError() {
      const errors = [];
      if (!this.$v.orderId.$dirty) return errors;
      !this.$v.orderId.required && errors.push("Order id es requerido");
      return errors;
    },
  },
  watch: {
    selectedTemplate: function (val) {
      if (val === "product_delivery_es" || val === "product_unavailable_es") {
        this.orderFieldRequired = true;
      } else {
        this.orderFieldRequired = false;
      }
    },
  },

  mounted() {
    if (this.sourceOrderId) {
      this.orderId = this.sourceOrderId;
    }

    if (this.sourceUserId) {
      this.userId = this.sourceUserId;
    }
  },
};
</script>
