<template>
  <v-card :elevation="0" class="d-flex flex-mobile justify-space-between">
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-card
      v-if="!['businessOwner'].includes($store.state.user.type)"
      class="text-left px-2 my-2 mx-1"
      :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'"
      elevation="0"
      min-width="200"
      style="border-radius: 17px !important; overflow: hidden"
    >
      <v-card-text class="text--primary">
        <div class="mb-n2">Ocho</div>
        <p class="title ma-0" style="color: #f06a25">Metadata:</p>
        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> Order ID en FireStore </span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item['.key'])"
            >
              {{ item[".key"] }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> User ID en FireStore </span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.userId)"
            >
              {{ item.userId }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> Business ID en FireStore </span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.businessId)"
            >
              {{ item.businessId }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> Origen de la Orden </span>
        <span style="d-block">
          {{ item.orderChannel | filterChannel }}
        </span>
      </v-card-text>
    </v-card>

    <v-card
      class="text-left px-2 my-2 mx-1 flex-grow-1"
      :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'"
      elevation="0"
      style="border-radius: 17px !important; overflow: hidden"
    >
      <v-card-text class="text--primary">
        <v-alert
          style="padding: 8px"
          color="#555555"
          v-if="item.rejectedReason"
          type="error"
        >
          <p class="pl-1 mx-0" style="color: white">
            <small>Cancelada por {{ item.rejectedBy.name }}</small>
          </p>
          {{ item.rejectedReason }}
        </v-alert>
        <div class="mb-n2">{{ item.userName }}</div>
        <p class="title ma-0" style="color: #f06a25">Detalles del Cliente:</p>

        <div class="mb-5" v-if="item.coupon && item.coupon.source">
          <v-divider class="my-2 mb-5"></v-divider>
          <h2>
            <span class="d-block">
              Cupón utilizado
              <b style="color: green">{{ item.coupon.code }}</b> con beneficio
              de
              <b style="color: green"
                >{{ currency(item.couponDiscount) }}
                <small v-if="!item.coupon.isFixed" style="color: black"
                  >({{ item.coupon.amount }}%)</small
                >
              </b>

              aplicado al
              <b style="color: green">{{
                item.coupon.type | filterCouponType
              }}</b>
              de la orden.
            </span>
          </h2>
        </div>
        <v-divider class="my-2"></v-divider>

        <v-row>
          <v-col cols="12" md="6">
            <span class="d-block font-weight-bold"> Método de pago </span>
            <v-chip
              :color="colorPaymentMethod(item.orderPaymentMethod)"
              style="display: inline-block"
              dark
              small
              class="font-weight-bold"
              :text-color="'white'"
            >
              {{ item.orderPaymentMethod | orderPaymentMethodFilter }}
            </v-chip>
          </v-col>
        </v-row>

        <v-divider
          v-if="
            fraudAnalysis &&
            fraudAnalysis.length &&
            !['businessOwner'].includes($store.state.user.type)
          "
          class="my-2"
        ></v-divider>

        <span
          v-if="
            fraudAnalysis &&
            fraudAnalysis.length &&
            !['businessOwner'].includes($store.state.user.type)
          "
          class="d-block font-weight-bold mb-0 pb-0"
        >
          Análisis de fraude
        </span>

        <v-alert
          v-if="
            fraudAnalysis &&
            fraudAnalysis.length &&
            !['businessOwner'].includes($store.state.user.type)
          "
          border="left"
          class="mt-3 fraud-alert"
          :color="fraudColor"
          dark
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p
                class="font-weight-bold"
                v-for="e of fraudAnalysis"
                :key="e.alert"
              >
                <!-- <i
                  style="font-size: 30px; color: gray"
                  class="fas fa-times mr-5 font-weight-bold"
                  v-if="!e.result"
                ></i> -->

                <i
                  style="font-size: 30px"
                  class="fas fa-exclamation-circle mr-5 red--text darken-4 font-weight-bold"
                  v-if="e.result"
                ></i>

                {{ e.alert }}
              </p>
            </v-col>
            <!-- <v-col class="percent" cols="12" sm="4" md="4">
              <div>
                <p>Sospecha de fraude</p>
                <h1>{{ getFraudPercent }}</h1>
              </div>
            </v-col> -->
          </v-row>
        </v-alert>

        <v-divider
          v-if="item.orderPaymentMethod == 'card' && item.cardData"
          class="my-2"
        ></v-divider>
        <v-row
          v-if="
            item.orderPaymentMethod == 'card' &&
            item.cardData &&
            !['businessOwner'].includes($store.state.user.type)
          "
        >
          <v-col cols="12" sm="12" md="12">
            <span class="d-block font-weight-bold mb-0 pb-0">
              Detalles de la tarjeta
            </span>

            <div class="mt-1 ml-1">
              <p v-if="item.cardData.issuer" class="ma-0">
                Banco emisor : <b>{{ item.cardData.issuer }}</b>
              </p>
              <p class="ma-0 mt-2">
                Tipo de tarjeta :
                <b
                  >{{ item.cardData.brand }} {{ item.cardData.type }}
                  {{ item.cardData.product }}</b
                >
              </p>
              <p v-if="item.cardData.endsIn" class="ma-0 mt-2 mb-3">
                Terminación : <b>{{ item.cardData.endsIn }}</b>
              </p>
              <a
                v-if="
                  ['sudo', 'admin', 'AF', 'ACC'].includes(
                    $store.state.user.type
                  )
                "
                @click="showUserCards"
                class="ml-1"
                ><i class="fas fa-credit-card mr-2"></i>Ver tarjetas del
                cliente</a
              >
            </div>
          </v-col>
        </v-row>

        <!-- <v-divider v-if="item.cardsAdded" class="my-2"></v-divider>
        <span v-if="item.cardsAdded" class="d-block font-weight-bold">
          Tarjetas agregadas: {{ item.cardsAdded }}
        </span>

        <v-divider v-if="item.addressesAdded" class="my-2"></v-divider>
        <span v-if="item.addressesAdded" class="d-block font-weight-bold">
          Direcciones agregadas: {{item.addressesAdded}}
        </span> -->

        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> Teléfono del Cliente </span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.userPhone)"
            >
              {{ item.userPhone }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
        <a
          v-if="['sudo', 'admin', 'AF', 'ACC'].includes($store.state.user.type)"
          @click="whatsappDialog"
          class="ml-3"
          ><i class="fab fa-whatsapp mr-1"></i>Iniciar conversación</a
        >

        <v-divider v-if="item.userEmail" class="my-2"></v-divider>
        <span v-if="item.userEmail" class="d-block font-weight-bold">
          Correo electrónico del Cliente
        </span>
        <v-tooltip v-if="item.userEmail" right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.userEmail)"
            >
              {{ item.userEmail }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>

        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold">Estado del Onboarding</span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="filterOnboardingColor(item.userOnboardingStatus)"
              style="display: inline-block"
              v-on="on"
              dark
              text-color="white"
              @click="copyToClipboard(item.userOnboardingStatus)"
            >
              {{ item.userOnboardingStatus | filterOnboarding }}
              <v-icon class="ml-3"> {{ item.userOnboardingStatus | filterOnboardingIcon }}</v-icon>
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>

        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="12" md="6">
            <span class="d-block font-weight-bold">
              Dirección del Cliente:
            </span>
            <a
              style="display: block"
              :href="
                'https://www.google.com/maps/search/?api=1&query=' +
                item.userAddressLatitude +
                ',' +
                item.userAddressLongitude
              "
              target="_blank"
            >
              {{
                item.userAddressLatitude
                  ? parseFloat(item.userAddressLatitude).toFixed(6) + ","
                  : "no disponible"
              }}{{
                item.userAddressLongitude
                  ? parseFloat(item.userAddressLongitude).toFixed(6)
                  : ""
              }}
              <i class="ml-1 fas fa-external-link-alt"></i>
            </a>
            <a class="ml-1 mt-4" @click="validateZoneDialog = true"
              ><i class="fas fa-map"></i> Validar dirección</a
            >
          </v-col>
          <v-col cols="12" md="6" v-if="item.location">
            <span class="d-block font-weight-bold"> Ubicación Actual: </span>
            <a
              style="display: block"
              :href="
                'https://www.google.com/maps/search/?api=1&query=' +
                item.location[0] +
                ',' +
                item.location[1]
              "
              target="_blank"
            >
              {{
                item.location[0]
                  ? parseFloat(item.location[0]).toFixed(6) + ","
                  : "no disponible"
              }}{{
                item.location[1] ? parseFloat(item.location[1]).toFixed(6) : ""
              }}
              <i class="ml-1 fas fa-external-link-alt"></i>
            </a>
          </v-col>
        </v-row>
        <span class="d-block">{{ item.userAddressName }}</span>
        <div class="d-block">
          {{ item.userAddressNeighborhood }}
        </div>

        <div v-if="item.table">
          <v-divider></v-divider>
          <span class="d-block font-weight-bold">
            Mesa en la que se encuentra
          </span>
          <p class="ml-1">{{ item.table }}</p>
        </div>

        <div v-if="item.room">
          <v-divider></v-divider>
          <span class="d-block font-weight-bold">
            Habitación en la que se encuentra
          </span>
          <p class="ml-1">{{ item.room }}</p>
        </div>
      </v-card-text>
    </v-card>

    <v-card
      class="text-left px-2 my-2 pb-2 mx-1 flex-grow-1"
      :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'"
      elevation="0"
      min-width="200"
      style="border-radius: 17px !important; overflow: hidden"
    >
      <v-card-text class="text--primary">
        <div class="mb-n2">{{ item.businessName }}</div>
        <p class="title ma-0" style="color: #f06a25">Detalles del Negocio:</p>
        <template v-if="item.businessPhone">
          <v-divider class="my-2"></v-divider>
          <span class="d-block font-weight-bold"> Teléfono del contacto </span>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-chip
                :color="
                  $vuetify.theme.dark
                    ? 'rgba(0, 0, 0, 0.4)'
                    : 'rgba(0, 0, 0, 0.1)'
                "
                style="display: inline-block"
                v-on="on"
                dark
                :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                @click="copyToClipboard(item.businessPhone)"
              >
                {{ item.businessPhone }}
              </v-chip>
            </template>
            <i class="far fa-copy mr-2"></i>
            <span>Click para copiar</span>
          </v-tooltip>
          <!-- adminBusinessPhone -->
        </template>

        <template v-if="item.adminBusinessPhone">
          <v-divider class="my-2"></v-divider>
          <span class="d-block font-weight-bold"> Teléfono del Negocio </span>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-chip
                :color="
                  $vuetify.theme.dark
                    ? 'rgba(0, 0, 0, 0.4)'
                    : 'rgba(0, 0, 0, 0.1)'
                "
                style="display: inline-block"
                v-on="on"
                dark
                :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                @click="copyToClipboard(item.adminBusinessPhone)"
              >
                {{ item.adminBusinessPhone }}
              </v-chip>
            </template>
            <i class="far fa-copy mr-2"></i>
            <span>Click para copiar</span>
          </v-tooltip>
          <!-- adminBusinessPhone -->
        </template>

        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold"> Dirección del Negocio: </span>
        <a
          style="display: block"
          :href="
            'https://www.google.com/maps/search/?api=1&query=' +
            item.businessGeoAddress.geopoint.latitude +
            ',' +
            item.businessGeoAddress.geopoint.longitude
          "
          target="_blank"
        >
          {{
            item.businessGeoAddress.geopoint.latitude
              ? parseFloat(item.businessGeoAddress.geopoint.latitude).toFixed(
                  6
                ) + ","
              : "no disponible"
          }}{{
            item.businessGeoAddress.geopoint.longitude
              ? parseFloat(item.businessGeoAddress.geopoint.longitude).toFixed(
                  6
                )
              : ""
          }}
          <i class="ml-1 fas fa-external-link-alt"></i>
        </a>
        <div class="d-block">{{ item.businessZone }}</div>
        <template v-if="item.deliveryInfo">
          <template v-if="item.deliveryInfo.driverName">
            <v-divider class="my-2"></v-divider>
            <span class="d-block font-weight-bold"> Motorista Asignado </span>
            <span class="d-block" style="text-transform: capitalize">
              {{ item.deliveryInfo.driverName }}
            </span>
            <v-divider class="my-2"></v-divider>
            <span class="d-block font-weight-bold">
              Teléfono del Motorista
            </span>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.deliveryInfo.phone)"
                >
                  {{ item.deliveryInfo.phone }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
            <div v-if="item.orderInstructions">
              <v-divider class="my-2"></v-divider>
              <span class="d-block font-weight-bold">
                Instrucciones para el Motorista
              </span>
              <span class="d-block ml-1" style="text-transform: capitalize">{{
                item.orderInstructions
              }}</span>
            </div>
          </template>
        </template>
      </v-card-text>
    </v-card>

    <v-card
      class="text-left my-2 px-2 mx-1"
      max-width="500"
      :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'"
      elevation="0"
      style="border-radius: 17px !important; overflow: hidden"
    >
      <v-card-text class="text--primary">
        <div class="mb-n2">{{ item.shortId }}</div>
        <p class="title ma-0" style="color: #f06a25">Detalles de la Orden:</p>
        <template v-if="item.tinName && item.tinNumber">
          <v-divider class="my-2"></v-divider>
          <span class="d-block font-weight-bold text--red"> Con RTN </span>
          <span style="d-block">{{ item.tinName }}</span>
          <br />
          <span style="d-block">{{ item.tinNumber }}</span>
        </template>

        <v-divider class="my-2"></v-divider>
        <span class="d-block font-weight-bold">
          Tiempo de preparación de la orden
        </span>
        <p class="pl-2">
          {{ item.processTime ? item.processTime : 20 }} minutos
        </p>
        <div
          class="d-block"
          v-for="(product, itemObjKey) in item.products"
          :key="
            product.id
              ? itemObjKey + 1 + product.id + item['.key']
              : itemObjKey + 1 + item['.key']
          "
        >
          <v-divider class="my-2"></v-divider>
          <v-row>
            <v-col cols="12" sm="9" md="9">
              <span class="font-weight-bold quantity" style="font-size: 15px">
                {{ product.quantity }}x </span
              >.
              {{ product.name }}
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <span class="font-weight-bold" style="font-size: 15px">
                {{ product.priceSubTotal.toFixed(2) }}
              </span>
            </v-col>
          </v-row>
          <span v-if="product.comments" style="white-space: pre-wrap">
            {{ product.comments }}
          </span>
          <span v-else class="caption" style="opacity: 0.4">
            Producto sin detalles/comentarios
          </span>

          <span
            style="font-size: 15px"
            class="d-block font-weight-bold ml-2 mt-3"
            v-if="product.addons && product.addons.length > 0"
          >
            Complementos:
          </span>

          <div
            v-for="(addon, i) in groupAddons(product.addons)"
            :key="i"
            class="d-block subtitle-1"
          >
            <span style="font-size: 15px" class="ml-5">
              - {{ addon.groupName }}
            </span>
            <div
              v-for="(option, optKey) in addon.options"
              :key="optKey"
              style="font-size: 13px"
              class="ml-8 font-weight-bold"
            >
              <span style="font-size: 16px">{{ option.quantity }}x. </span>
              {{ option.name }} | {{ option.price }}
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="whDialog" v-if="whDialog" max-width="600px">
      <add-message
        :sourceOrderId="item['.key']"
        :sourceUserId="item.userId"
        @cancel="whDialog = false"
        @success="handleSuccess"
        :sourceOrder="item"
      ></add-message>
    </v-dialog>

    <v-dialog
      persistent
      v-model="cardsDialog"
      v-if="cardsDialog"
      max-width="90%"
    >
      <user-cards :user="user" @cancel="cardsDialog = false"></user-cards>
    </v-dialog>

    <v-dialog
      v-model="validateZoneDialog"
      v-if="validateZoneDialog"
      persistent
      max-width="70%"
    >
      <map-geofence
        :longitude="item.userAddressLongitude"
        :latitude="item.userAddressLatitude"
        @cancel="validateZoneDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import AddMessage from "../sudo/messages/AddMessage";
// import moment from "moment-timezone";
import userCards from "../sudo/users/edit-cards.vue";
// import {  mapState } from "vuex";

import { db } from "@/firebase";

import mapGeofence from "@/components/map-geofence";

export default {
  name: "order-details",
  props: ["item"],
  components: {
    AddMessage,
    userCards,
    mapGeofence,
  },
  data() {
    return {
      whDialog: false,
      snackbar: false,
      snackbarText: "",
      cardsDialog: false,
      user: null,
      validateZoneDialog: false,
    };
  },

  computed: {
    getFraudPercent() {
      let fraudPercent = 0;

      this.item.fraudAnalysis.forEach((e) => {
        if (e.result) {
          fraudPercent = fraudPercent + 100 / this.item.fraudAnalysis.length;
        }
      });

      return `${fraudPercent.toFixed(2)} %`;
    },
    fraudColor() {
      let fraudPercent = 0;

      this.item.fraudAnalysis.forEach((e) => {
        if (e.result) {
          fraudPercent = fraudPercent + 100 / this.item.fraudAnalysis.length;
        }
      });

      if (fraudPercent > 0 && fraudPercent < 50) {
        return "#ffdd37b0";
      }

      if (fraudPercent > 0) {
        return "#df323b73";
      }

      return "#4caf50ab";
    },
    fraudAnalysis() {
      return this.item.fraudAnalysis
        ? this.item.fraudAnalysis.filter((e) => e.result)
        : null;
    },
  },
  filters: {
    filterChannel(type) {
      let channel = {
        App_android: "Android App",
        App_iOS: "iOS App",
        ocho_cargo: "Ocho Cargo",
        menuhn: "Ocho Menu HN",
      };

      return type && channel[type] ? channel[type] : "";
    },

    filterOnboarding(value) {
      let l = {
        placed: "Colocada",
        reviewed: "Corecciones pendientes",
        rejected: "Rechazado",
        completed: "Completado",
      };

      return value && l[value] ? l[value] : "No realizado";
    },

    filterOnboardingIcon(value) {
      let l = {
        placed: "fas fa-location-pin",
        reviewed: "fas fa-clock",
        rejected: "fas fa-times",
        completed: "fas fa-check-double",
      };

      return value && l[value] ? l[value] : "fas fa-times";
    },

    filterCouponType(type) {
      let typeNames = {
        shipping: "ENVIO",
        subtotal: "SUBTOTAL",
        total: "TOTAL",
      };

      return typeNames[type];
    },
    orderPaymentMethodFilter(method) {
      switch (method) {
        case "card":
          return "Tarjeta";
        case "cash":
          return "Efectivo";
        case "tengo":
          return "Tengo";
        case "tigoMoney":
          return "Tigo Money";
        case "dilo":
          return "Dilo";
        case "coupon":
          return "Cupón";
        case "opl":
          return "Ocho Pay";
        default:
          return "Desconocido";
      }
    },
  },
  methods: {
    filterOnboardingColor(value) {
      let l = {
        placed: "blue",
        reviewed: "warning",
        rejected: "red",
        completed: "green",
      };

      return value && l[value] ? l[value] : "red";
    },
    colorPaymentMethod(method) {
      switch (method) {
        case "card":
          return "pink";
        case "cash":
          return "light-green";
        case "tengo":
          return "indigo";
        case "tigoMoney":
          return "#00377b";
        case "dilo":
          return "#f14244";
        case "coupon":
          return "purple";
        case "opl":
          return "#f06a25";
        default:
          return "black";
      }
    },
    whatsappDialog() {
      this.whDialog = true;
    },
    async showUserCards() {
      await this.$binding("user", db.collection("users").doc(this.item.userId));
      this.cardsDialog = true;
    },
    handleSuccess(data) {
      this.snackbarText = data;
      this.snackbar = true;
      this.whDialog = false;
    },

    groupAddons(addons) {
      let result = [];
      if (addons) {
        addons.forEach((addon) => {
          let search = result.find((item) => item.groupName == addon.groupName);

          if (search) {
            search.options.push({
              name: addon.name,
              quantity: addon.quantity,
              price: addon.productPrice ? addon.productPrice : 0,
            });
          } else {
            let newAddon = addon;
            newAddon.options = [
              {
                name: addon.name,
                quantity: addon.quantity,
                price: addon.productPrice ? addon.productPrice : 0,
              },
            ];
            result.push(newAddon);
          }
        });
      }
      return result;
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/_responsive.scss";
.quantity {
  font-size: 20px !important;
  color: black !important;
}

.fraud-alert {
  width: 1000px;
}
@include responsive(mobile) {
  .flex-mobile {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .fraud-alert {
    width: 100%;
  }
}

.percent {
  display: grid;
  justify-content: center;
  align-items: center;

  div {
    p {
      color: #FF1744;
      font-weight: bold;
    }
    h1 {
      color: rgb(83, 82, 82);
      font-size: 50px;
      font-weight: bold;
    }
  }
}
</style>