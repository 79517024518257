<template>
  <v-card class="py-1">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="justify-center px-3">
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        small
        absolute
        v-if="!loading"
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
      <span class="headline text-center">
        ¿Seguro que desea colocar la orden
        <b> {{ editedItem.shortId }} </b>?
        <div class="mt-2 d-block text-center caption" style="width: 100%">
          Ordenada de
          <b>{{ editedItem.businessName || "MANDADITOS" }}</b>
          <br />por
          <b>{{ editedItem.userName }}</b>
          {{ editedItem.progressPlacedAtRelative }}
        </div>

        <div
          v-if="editedItem.orderType == 'pickUp'"
          class="mt-2 d-block text-center caption"
          style="width: 100%"
        >
          <b class="primary--text title">
            ¡El cliente recogerá la orden en el comercio!
          </b>
        </div>
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <!-- <v-card-text style="height: auto; overflow-x: hidden"> </v-card-text> -->
    <v-divider></v-divider>
    <v-card-actions>
      <v-row align="center" class="d-flex my-1 mx-4">
        <v-btn
          rounded
          class="flex-grow-1"
          large
          :elevation="0"
          :minHeight="56"
          :disabled="loading"
          @click="$emit('cancel')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="flex-grow-1 ml-2"
          large
          :elevation="0"
          :minHeight="56"
          :loading="loading"
          @click="acceptOrder()"
        >
          Aceptar
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

import { fb } from "@/firebase";

export default {
  name: "accept-order",
  props: ["item", "process"],
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
      editedItem: {},
    };
  },

  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async acceptOrder() {
      this.loading = true;

      let data = {
        orderId: this.editedItem[".key"],
      };

      var httpScheduledOrdersAccept = fb
        .functions()
        .httpsCallable("httpScheduledOrdersAccept");
      httpScheduledOrdersAccept(data)
        .then(() => {
          this.loading = false;
          this.$emit("success");
        })
        .catch((err) => {
          this.loading = false;
          this.snackbar = true;
          this.snackbarText = err.message;
        });
    },
  },

  async mounted() {
    this.editedItem = JSON.parse(JSON.stringify(this.item));
  },
};
</script>
